import React, {ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, KeyboardEvent, useState} from 'react'
import s from './UCInputText.module.css'
import Focus from '../focus/Focus';
import PlaceHolderLabel from '../place-holder-label/PlaceHolderLabel';
import MaskedInput from 'react-text-mask';

type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>


type UCInputTextPropsType = DefaultInputPropsType & {
    onChangeText?: (value: string) => void
    onEnter?: () => void
    error?: string
    spanClassName?: string
    touched?: boolean
    mask?: any
    labelText?: string
}

const MaskedUcInput: React.FC<UCInputTextPropsType> = (
    {
        // get and ignore to not re-init new type
        onChange, onChangeText,
        onKeyPress, onEnter,
        error,
        className, spanClassName,
        placeholder,
        labelText ,
        touched,
        mask,
        name,
        ...restProps
    }
) => {

    const [focused, setFocused] = useState(false)

    const onChangeCallback = (e: ChangeEvent<HTMLInputElement>) => {
        onChange // if prop onChange
        && onChange(e)

        onChangeText && onChangeText(e.currentTarget.value)
    }
    const onKeyPressCallback = (e: KeyboardEvent<HTMLInputElement>) => {
        onKeyPress && onKeyPress(e);

        onEnter // if prop onEnter
        && e.key === 'Enter'
        && onEnter()
    }

    const err = touched && error ? error : ''
    const finalSpanClassName = `${s.error} ${spanClassName ? spanClassName : ''}`
    const finalInputClassName = `${err ? s.errorInput : s.UCInput} ${className}`

    const {field}: any = {...restProps}
    return (
        <div className={s.UCInputContainer}>
          <PlaceHolderLabel type={'inputText'} focused={focused || restProps.value !== ''} text={labelText}/>
            <MaskedInput
                    onFocus={() => setFocused(true)}
                    onBlurCapture={() => setFocused(false)}
                    type={'text'}
                    onChange={onChangeCallback}
                    onKeyPress={onKeyPressCallback}
                    className={finalInputClassName}
                    placeholder={placeholder}
                    {...field}
                    {...restProps}
                    mask={mask}
                    name={name}
                />


            {err !== '' ? <span className={finalSpanClassName}>{error}</span> : null}
        </div>
    );
};

export default MaskedUcInput;