import React from 'react';
import s from './PlaceHolderLabel.module.css';

type PlaceHolderLabelPropsType = {
    focused: boolean
    text?: string
    type: string
}

const PlaceHolderLabel = ({focused, text, type}: PlaceHolderLabelPropsType) => {

    const finalClassName = type === 'inputText' ? s.labelText : `${s.labelText} ${s.labelTextArea}`

    return (
        <label className={focused ? `${finalClassName} ${s.active}` : finalClassName}>{text}</label>
    );
};

export default PlaceHolderLabel;