type ServiceType = {
    name: string
    id: number
    collapsed: boolean
    description: string[]
}
export type OurServicesType = ServiceType[]


export const ourServices: OurServicesType = [
    {
        name: '1   / Разработка сайтов',
        id: 1,
        collapsed: false,
        description: [
            'Landing-page',
            'Сайт визитка',
            'Корпоративный сайт',
            'Интернет-магазин на Битрикс',
            'Эксклюзивный продукт',
            'Готовые решения от нашей компании'
        ]
    },
    {
        name: '2   / Разработка мобильных приложений',
        id: 2,
        collapsed: true,
        description: [
            'Разработка с нуля приложений для Android и iOS от проектирования\n' +
            'до размещения в магазинах приложений'
        ]
    },
    {
        name: '3   / Разработка дизайна',
        id: 3,
        collapsed: true,
        description: [
            'Функциональный дизайн на основе исследований и аналитики\n' +
            'целевой аудитории.'
        ]
    },
    {
        name: '4   / Модернизация систем',
        id: 4,
        collapsed: true,
        description: [
            'Рефакторинг кода',
            'Повышение производительности',
            'Расширение функционала',
            'Доработка дизайна сайта'
        ]
    },
    {
        name: '5   / Техническая поддержка',
        id: 5,
        collapsed: true,
        description: [
            'В работах по поддержке сайта принимают участие специалисты того ' +
            'профиля, который потребуется исходя из поставленной задачи.',
            'Наполнение существующих или новых страниц',
            'Восстановление сайта после модернизации или ' +
            'профилактических работ, проводимых на хостинг-площадке',
            'Консультации "Почему не работает мой сайт" и по вопросам' +
            'работы с программным продуктом CMS.',
            'Online консультации в режиме реального времени по' +
            'вопросам, связанным с работой с CMS'
        ]
    },
    {
        name: '6   / Внедрение Битрикс.24 CRM',
        id: 6,
        collapsed: true,
        description: [
            'Выстраивание воронки продаж',
            'Автоматизация бизнес-процессов',
            'Настройка единого места взаимодействия с клиентами',
            'Интеграция с сайтом и продуктами 1С',
            'Обучение сотрудников',
            'Поддержка работы портала'
        ]
    },
]


export const steps = [
    {
        name: '1 / Обсуждение идеи',
        id: 1,
    },
    {
        name: '2 / Анализ',
        id: 2,
    },
    {
        name: '3 / Составление ТЗ',
        id: 3,
    },
    {
        name: '4 / Разработка',
        id: 4,
    },
    {
        name: '5 / Тестирование',
        id: 5,
    },
    {
        name: '6 / Развитие и поддержка',
        id: 6,
    },

]