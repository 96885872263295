export type CustomerFormType = {
    name: string,
    company: string,
    email: string,
    phone: string,
    task: string,
    amount: number,
    products: ItemsType
}
type ItemType = {
    value: string
}
export type ItemsType = Array<ItemType>

export const customerForm = {
    name: '',
    company: '',
    email: '',
    phone: '',
    task: '',
    amount: 0,
    products: [] as ItemsType
}
export const productsArr = [
    {value: 'Сайт'},
    {value: 'Мобильное приложение'},
    {value: 'Дизайн продукта'},
    {value: 'Модернизация систем'},
    {value: 'Техническая поддержка'},
    {value: 'Внедрение CRM'}
]
export const amountArr = [
    {value: 0, label: 'Не знаю',},
    {value: 0, label: '↓ 300 тыс.'},
    {value: 1, label: '300-600 т.'},
    {value: 2, label: '600-1 млн.',},
    {value: 3, label: '1-1.5 млн.',},
    {value: 4, label: '1.5 млн. ↑'}
]

export const littleFormInit = {
    phone: ''
}
