import React from 'react';
import s from './Focus.module.css';

type FocusPropsType = {
    focused: boolean
}

const Focus = ({focused}: FocusPropsType) => {
    return (
        <div className={focused ? `${s.focus} ${s.active}` : s.focus}></div>
    );
};

export default Focus;