import React from 'react';
import s from './UCModal.module.css'

type ModalFormPropsType = {
    active: boolean
    setActive: (value: boolean) => void
    children: any
}

const UCModal = ({ active, setActive, children }: ModalFormPropsType) => {

    //style calculating
    const modalClassName = active ? `${s['modalContainer']} ${s['active']}` : s.modalContainer

    // Close modal
    const closeModal = () => {
        setActive(false)
    }

    return (
        <div className={modalClassName} onClick={closeModal}>
            <div className={s.modalContent} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </div>
    );
};

export default UCModal;