import s from './about-us-card.module.css'

interface IProps {
  header: string;
  styleType: string;
  cardNumber: string;
  children?: any
}
const AboutUsCard = (props: IProps) => {
  return (
    <div className={`${s.container} ${s[props.styleType]}`}>
      <div className={s.cardNumber}>{props.cardNumber}</div>
      <div className={s.content}>
        <h3 className={s.header}>{props.header}</h3>
        {props.children}
      </div>

    </div>
  );
};

export default AboutUsCard;