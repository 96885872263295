export const ucSliderStyle = {

    '& .MuiSlider-track': {
        height: '3px',
        borderRadius: '100px',
        border: 'none',
        color: '#8941E8',
    },
    '& .MuiSlider-mark': {
        height: '6px',
        width: '2px',
        top: '23px',
        borderRadius: '50px',
        color: '#E7E8EA',
    },
    '& .MuiSlider-rail': {
        borderRadius: '100px',
        border: 'none',
        color: '#E7E8EA',
    },
    '& .MuiSlider-thumb': {
        boxShadow: 'none',
        height: '10px',
        borderRadius: '100px',
        width: '10px',
        opacity: '100%',
        color: '#8941E8',
        backgroundColor: '#8941E8',
        border: 'solid #805FA7 3px',
    },
    // '& .MuiSlider-thumb :after': {
    //     color: '#B19DC9',
    // },
    // '& .MuiSlider-thumb :before': {
    //     color: '#B19DC9',
    // },
    '& .MuiSlider-markLabel': {
        color: '#A4A5A6',
        fontSize: '14px',
        lineHeight: '12px',
        '@media (max-width: 500px)': {
            width: '45px',
            textWrap: 'wrap'
        },

    },
    // '& .MuiSlider-thumb: hover': {
    //     boxShadow: 'none',
    // },
    // '& .MuiSlider-thumb: focused': {
    //     boxShadow: 'none',
    //     background: 'none',
    //     color: 'transparent'
    // },
    '& .MuiSlider-thumb: hover': {
        boxShadow: 'none',
    },
    '& .MuiSlider-thumb.Mui-focusVisible': {
        boxShadow: 'none',
    },
    '& .MuiSlider-markActive': {
        background: 'none',
        fontSize: '15px'
    }
}

export const ucSliderStyleDisabled = {

    '& .MuiSlider-track': {
        display: 'none'
    },
    '& .MuiSlider-mark': {
        display: 'none'
    },
    '& .MuiSlider-rail': {
        display: 'none'
    },
    '& .MuiSlider-thumb': {
        display: 'none'
    },
    // '& .MuiSlider-thumb :after': {
    //     color: '#B19DC9',
    // },
    // '& .MuiSlider-thumb :before': {
    //     color: '#B19DC9',
    // },
    '& .MuiSlider-markLabel': {
        display: 'none'
    },
    // '& .MuiSlider-thumb: hover': {
    //     boxShadow: 'none',
    // },
    // '& .MuiSlider-thumb: focused': {
    //     boxShadow: 'none',
    //     background: 'none',
    //     color: 'transparent'
    // },
    '& .MuiSlider-thumb: hover': {
        display: 'none'
    },
    '& .MuiSlider-thumb.Mui-focusVisible': {
        display: 'none'
    },
    '& .MuiSlider-markActive': {
        display: 'none'
    }
}