import React from 'react';
import s from './Contacts.module.css'
import ModuleMap from '../../features/ModuleMap';

const Contacts = () => {
    return (
        <div className={s.container}>
            <div className={s.contactsInfo} id={'contacts'}>

              <div className={s.contactsInfoInner}>
                <div className={s.contactsInfoInnerText}>
                  <h1>Наш офис</h1>
                  <p>Москва, Измайловское шоссе дом 71, корпус 4Г‑Д, офис 101</p>
                </div>
                <ModuleMap/>
              </div>
            </div>
        </div>
    );
};

export default Contacts;