import s from './TitleScreen.module.css'
import LittleForm from '../../features/uc-formic-form/little-form/LittleForm';
import UCButton from '../../common/button/UCButton';
import { motion } from 'framer-motion';
import {fromLeft} from '../animations/animations';
import image from '../../assets/img.png'
type TitleScreenPropsType = {
    setActive: (val: boolean) => void
}

const TitleScreen = (props: TitleScreenPropsType) => {
    const { setActive } = props

    return (
        <div id={'title'} className={s.container}>
            <div className={s.desktop}>
                <div className={s.titleScreenWrapper}>
                    <motion.div
                      initial={'hidden'}
                      whileInView={'visible'}
                      variants={fromLeft}
                      className={s.greetingsContainer}>

                        <motion.h1 variants={fromLeft} custom={1}>Разработка, развитие и поддержка
                            <em> веб&#8209;сервисов </em>
                            и
                            <em> мобильных&nbsp;приложений</em></motion.h1>

                        <motion.span variants={fromLeft} custom={2}>От идеи до реализации 1&nbsp;клик</motion.span>
                        <motion.span variants={fromLeft} custom={2}>Оставьте заявку и наш менеджер свяжется с вами в
                            течение 15 минут
                        </motion.span>

                        <motion.div variants={fromLeft} custom={3} className={s.personalInfo}>
                            <LittleForm className={s.miniForm}/>
                            <p>Нажмая кнопку "Отправить" вы даете согласие
                                на обработку&nbsp;<a
                                  href={'https://ucdev.ru/privacy-policy.pdf'}>персональных&nbsp;данных</a>
                            </p>
                        </motion.div>
                        <UCButton
                          onClick={() => setActive(true)}
                          className={s.mobileVersionButton}>
                            Оставить&nbsp;заявку
                        </UCButton>
                    </motion.div>
                    <div className={s.pictureWrapper}>

                        <div className={s.picture}>
                            <img alt={''} src={image}/>
                        </div>
                    </div>

                </div>
            </div>

            <div className={s.mobile}>
                <div className={s.titleScreenWrapper}>
                    <motion.div
                      initial={'hidden'}
                      whileInView={'visible'}
                      variants={fromLeft}
                      className={s.greetingsContainer}>
                        {/*<span className={s.logo}></span>*/}

                        <motion.h1 variants={fromLeft} custom={1}>Разработка, развитие и поддержка
                            <em> веб&#8209;сервисов </em>
                            и
                            <em> мобильных&nbsp;приложений</em></motion.h1>


                        <div className={s.pictureWrapper}>

                            <div className={s.picture}>
                                <img alt={''} src={image}/>
                            </div>
                        </div>

                        <motion.span variants={fromLeft} custom={2}>От идеи до реализации 1&nbsp;клик</motion.span>
                        <motion.span variants={fromLeft} custom={2}>Оставьте заявку и наш менеджер свяжется с вами в
                            течение 15 минут
                        </motion.span>

                        <motion.div variants={fromLeft} custom={3} className={s.personalInfo}>
                            <LittleForm className={s.miniForm}/>
                            <p>Нажмая кнопку "Отправить" вы даете согласие
                                на обработку&nbsp;<a
                                  href={'https://ucdev.ru/privacy-policy.pdf'}>персональных&nbsp;данных</a>
                            </p>
                        </motion.div>
                        <UCButton
                          onClick={() => setActive(true)}
                          className={s.mobileVersionButton}>
                            Оставить&nbsp;заявку
                        </UCButton>
                    </motion.div>

                </div>
            </div>


        </div>
    );
};

export default TitleScreen;