export const fromLeft = {
    hidden: {
        x: -100,
        opacity: 0
    },
    visible: (custom: number = 1 ) => ({
        x: 0,
        opacity: 1,
        transition: {delay: custom * 0.2}
    })
}
export const fromRight = {
    hidden: {
        x: 100,
        opacity: 0
    },
    visible: (custom: number = 1 ) => ({
        x: 0,
        opacity: 1,
        transition: {delay: custom * 0.2}
    })
}
export const fromBottom = {
    hidden: {
        y: 100,
        opacity: 0
    },
    visible: (custom: number = 1 ) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.2}
    })
}
export const fromTop = {
    hidden: {
        y: -100,
        opacity: 0
    },
    visible: (custom: number = 1 ) => ({
        y: 0,
        opacity: 1,
        transition: {delay: custom * 0.2}
    })
}
