import React, {useRef, useState} from 'react';
import UCButton from '../../../common/button/UCButton';
import s from './LittleForm.module.css'
import emailjs from '@emailjs/browser';
import CustomizedSnackbars from '../../snackbar/SnackBar';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {phoneNumberMask} from '../../../common/mask/mask';
import MaskedUcInput from '../../../common/uc-input/MaskedUCInput';
import {littleFormInit} from '../../../state/customerForm';


type LittleFormPropsType = {
    className?: string
    spanClassName?: string
}


const LittleForm = ({className, spanClassName}: LittleFormPropsType) => {
    const littleForm = useRef<any>();

    const initValues = littleFormInit



    const [errSnackBar, setErrSnackBar] = useState('')
    // const onChangeHandler = (e: string) => {
    //     let maskedValue = masked.resolve(e)
    //     setValue(maskedValue)
    //     // setErr('')
    // }

    const [disabled, setDisabled] = useState(false)

    const sendEmail = (e: any) => {
        
        setDisabled(true)
        debugger
        emailjs.send('service_5zouc17', 'template_svthtig', e, 'Fcy7fgHITPL0M0wnk')
            .then((result) => {
                console.log(result.text)
                setDisabled(false)
                // alert('Message sent')
            })
            .catch((error) => {
                alert(error.text);
            })
    };

    const onSubmit = async (values: any, onSubmitProps: any) => {
        const token = '6497207492:AAFTCT0YdkmatHkNVzVASsjGHiNdlvoUdw4';
        const chat_id = "-1001989913251";
        setDisabled(true)
        onSubmitProps.setSubmitting(false)
        
        const entries = Object.values(values);
        entries[0] = `${'<b>'}Телефон:${'</b>'} ${entries[0]}`
        const message = entries.toString()
        const response = await fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&parse_mode=html&text=${message}`)
        setDisabled(false)
        onSubmitProps.resetForm()
    }
    const phoneRegExp = /\+7\(\d\d\d\)\s\d\d\d\-\d\d\d\d/
    const validationSchema = Yup.object({
        phone: Yup.string().matches(phoneRegExp, 'Введите правильный номер').required('Введите номер телефона')
    })
    return (
        <Formik validationSchema={validationSchema} initialValues={initValues} onSubmit={onSubmit}>
            <Form
                className={className ? className : s.container}
                ref={littleForm}
            >
                <CustomizedSnackbars setFlag={setDisabled} error={errSnackBar} flag={disabled}/>
                <Field name={'phone'}>
                    {(props: any) => {
                        const {field, form, meta} = props
                        return (
                            <MaskedUcInput
                                className={spanClassName}
                                {...meta}
                                mask={phoneNumberMask}
                                placeholder={'+7...'}
                                // labelText={'Ваш номер телефона'}
                                type={'text'}
                                {...field}
                                name={'phone'}
                                id={'phone'}
                            />
                        )
                    }}
                </Field>
                <UCButton type={"submit"}>Отправить</UCButton>
            </Form>
        </Formik>
    );
};

export default LittleForm;

//(?\d{3})?-? *\d{3}-? *-?\d{4}