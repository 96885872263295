import React, {useEffect, useState} from 'react';
import s from './drop-down-menu.module.css'
import drop_down_btn from '../../assets/drop_down_close.svg'
import drop_down_open from '../../assets/drop_down_open_menu.svg'
import {handleClickScroll} from '../../common/scroll-func/scroll';
import {useScroll} from 'framer-motion';

const UcDropDownMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const {scrollY} = useScroll()
  const [a, setA] = useState(0)

  useEffect(() => {
    return scrollY.onChange((latest) => {
      setA(latest)

    })
  }, [])
  function toggle() {
    setIsOpen(!isOpen)
  }
  return (
    <div className="simplemenu">
      <img onClick={toggle} className={s.drop_down_btn} src={isOpen ? drop_down_btn : drop_down_open} />
      <ul id="gmDropdown" className={isOpen ? s.dropdown_content_opened : s.dropdown_content_closed}>
        <li><span><a className={(a > 460 && a < 1548) ? s.a_active : ''}
                     onClick={() => handleClickScroll('about')}>О&nbsp;компании&nbsp;</a></span></li>
        <li><span><a className={(a > 1547 && a < 2533) ? s.a_active : ''}
                     onClick={() => handleClickScroll('services')}>Услуги&nbsp;</a></span></li>
        <li><span><a className={(a > 2533) ? s.a_active : ''}
                     onClick={() => handleClickScroll('form')}>Заполнить&nbsp;бриф&nbsp;</a></span></li>
        <div className={s.drop_down_contacts}>
          <span className={s.mailAndPhone}><a
            href={'mailto:go@ucdev.ru'}>go@ucdev.ru&nbsp;</a></span>
          <span className={s.mailAndPhone}><a
            href={'tel:+74951451085'}>+7&nbsp;(495)&nbsp;145&#8209;10&#8209;85&nbsp;</a></span>
        </div>


      </ul>
    </div>
  );
};

export default UcDropDownMenu;