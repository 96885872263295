import React from 'react';
import s from './AboutUs.module.css'
import {handleClickScroll} from '../../common/scroll-func/scroll';
import {steps} from '../../state/ourServices';
import { motion } from 'framer-motion'
import {fromLeft, fromRight} from '../animations/animations';
import AboutUsCard from '../../common/about-us-cards/AboutUsCard';

const AboutUs = () => {

    return (
        <div id={'about'} className={s.container}>
                <motion.div
                    variants={fromRight}
                    initial={'hidden'}
                    whileInView={'visible'}
                    viewport={{amount: 0.1}}
                    className={s.info}>

                    <div className={s.infoHeader}>
                        <h2>О нас</h2>
                    </div>
                    <div className={s.infoContent}>

                            <AboutUsCard
                              header={'Обсуждение идеи'}
                              styleType={'white'}
                              cardNumber={'01'}
                            >
                              Выстраивание воронки продаж.
                              Автоматизация бизнес-процессов.
                              Настройка единого места взаимодействия с клиентами.
                              Интеграция с сайтом и продуктами 1С.
                              Обучение сотрудников.
                              Поддержка работы портала.
                            </AboutUsCard>

                            <AboutUsCard
                              header={'Анализ'}
                              styleType={'violet_right_top'}
                              cardNumber={'02'}
                            >
                              После обсуждения идеи наша команда анализирует задачи
                              и составляет техническое задание,
                              на основе которого мы составляем дорожную карту проекта,
                              а также стоимость разработки как всего проекта,
                              так и с разбивкой по этапам.
                            </AboutUsCard>

                            <AboutUsCard
                              header={'Составление ТЗ'}
                              styleType={'white_3'}
                              cardNumber={'03'}
                            >
                              Далее команда разработки и дизайна приступают
                              к реализации по техническому заданию.
                              С нашей стороны вы всегда будете получатель отчеты
                              о проделанных работах, также удобно поддерживать
                              взаимосвязь с нами в любом мессенджере через персонального менеджера.
                            </AboutUsCard>

                            <AboutUsCard
                              header={'Разработка'}
                              styleType={'violet_left_center'}
                              cardNumber={'04'}
                            >
                              Готовый продукт тестируется и начинается его
                              продвижениие через SEO-оптимизацию и рекламные сети,
                              такие как Яндекс.Директ, ТаргетВК и другие.
                            </AboutUsCard>

                        <AboutUsCard
                          header={'Тестирование'}
                          styleType={'white'}
                          cardNumber={'05'}
                        >
                          Профессиональная команда поддержки, будет сопровождать ваш проект
                          и в кратчайшие сроки готова решить любую проблему,
                          будь то «падение» сервера или внесение правок.
                        </AboutUsCard>

                        <AboutUsCard
                          header={'Развитие и поддержка'}
                          styleType={'black'}
                          cardNumber={'06'}
                        >
                          Заполните <a
                          onClick={(e)=>{
                            e.preventDefault()
                            // eslint-disable-next-line no-restricted-globals
                            scrollTo(0, 2605)
                          }}
                          href="">форму</a>, мы свяжемся и обсудим ваш проект
                        </AboutUsCard>


                    </div>

                </motion.div>


            {/*<div className={s.steps}>*/}
            {/*    <ul>*/}
            {/*        {steps.map((el: any) => {*/}
            {/*            return <motion.li*/}
            {/*                initial={'hidden'}*/}
            {/*                whileInView={'visible'}*/}
            {/*                variants={fromLeft}*/}
            {/*                custom={el.id}*/}
            {/*                key={el.id}>{el.name}*/}
            {/*            </motion.li>*/}
            {/*        })}*/}
            {/*    </ul>*/}
            {/*</div>*/}
        </div>
    );
};

export default AboutUs;