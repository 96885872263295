import React from 'react';
import {Map, Placemark} from 'react-yandex-maps'

const ModuleMap = () => {

    return (
            <Map width={'80%'} height={'270px'} defaultState={{
                center: [55.79, 37.74],
                zoom: 9,
                controls: ['zoomControl', 'fullscreenControl']
            }} modules={['control.ZoomControl', 'control.FullscreenControl']}>
                <Placemark geometry={[55.791749, 37.748619]}/>
            </Map>
    )
        ;
};

export default ModuleMap;