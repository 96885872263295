import React, {useRef, useState} from 'react';
import {Formik, Form, Field} from 'formik';
import {amountArr, customerForm, CustomerFormType, productsArr} from '../../state/customerForm';
import s from './ClientsForm.module.css';
import UCButton from '../../common/button/UCButton';
import UCInputText from '../../common/uc-input/UCInputText';
import * as Yup from 'yup'
import UCTextArea from '../../common/uc-textarea/UCTextArea';
import UcRadioButtons from './UcRadioButtons';
import UcCheckBoxGroup from './UcCheckBoxGroup';
import CustomizedSnackbars from '../snackbar/SnackBar';
import {phoneNumberMask} from '../../common/mask/mask';
import MaskedUcInput from '../../common/uc-input/MaskedUCInput';

const UcForm = () => {

    const form = useRef<any>();

    const [disabled, setDisabled] = useState(false)

    const onSubmit = async (values: CustomerFormType, onSubmitProps: any) => {
        setDisabled(true)
        onSubmitProps.setSubmitting(false)
        onSubmitProps.setSubmitting(true)
        const token = '6497207492:AAFTCT0YdkmatHkNVzVASsjGHiNdlvoUdw4';
        const chat_id = "-1001989913251";
        const formData = {
            ...values,
            amount: amountArr.find(el => el.value.toString() === values.amount.toString())!.label,
            products: values.products.flat(1).join(', '),
        }
        const entries = Object.values(formData);
        entries[0] = `${'<b>'}Имя:${'</b>'} ${entries[0]}`
        entries[1] = `${'<b>'}Компания:${'</b>'} ${entries[1]}`
        entries[2] = `${'<b>'}Email:${'</b>'} ${entries[2]}`
        entries[3] = `${'<b>'}Телефон:${'</b>'} ${entries[3]}`
        entries[4] = `${'<b>'}Задача:${'</b>'} ${entries[4]}`
        entries[5] = `${'<b>'}Бюджет:${'</b>'} ${entries[5]}`
        entries[6] = `${'<b>'}Продукты:${'</b>'} ${entries[6]}`
        console.log(entries)
        const message = entries.join(`; ${'%0A'}`)
        const response = await fetch(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&parse_mode=html&text=${message}`)
        setDisabled(false)
        onSubmitProps.resetForm()
    }
    const phoneRegExp = /\+7\(\d\d\d\)\s\d\d\d\-\d\d\d\d/
    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    const validationSchema = Yup.object({
        name: Yup.string().required('Как вас зовут?'),
        email: Yup.string()
            .matches(emailRegExp, 'Введите корректный email')
            .required('Ваш email?'),
        phone: Yup.string()
            .matches(phoneRegExp, 'Ваш номер телефона')
            .required('Введите номер телефона')
    })
    return (
        <Formik ref={form} onSubmit={onSubmit}
                initialValues={customerForm}
                validationSchema={validationSchema}
                className={s.mainContainer}>
            <div className={s.mainContainer}>
                <div className={s.container}>
                    <CustomizedSnackbars flag={disabled}/>
                    <Form className={s.formContainer}>
                        <div id={'form'} className={s.formTitle}>
                            <h2>Обсудим ваш проект?</h2>
                            <p>Оставьте заявку, либо свяжитесь с нами по телефону</p>
                            <p><a href={'tel:+74951451085'}>+7&nbsp;(495)&nbsp;145&nbsp;10&nbsp;85</a> и мы сами всё
                                заполним.</p>
                        </div>

                        <div className={s.general}>
                            <div>
                                <div>

                                    <h4>Выберите тип продукта</h4>
                                    <div className={s.grid_3x3}>
                                        <UcCheckBoxGroup
                                            name={'products'}
                                            options={productsArr}
                                        />
                                    </div>
                                    <h4>Бюджет</h4>
                                    <div className={s.amountHorizontal}>
                                        <div className={s.amount}>
                                            <UcRadioButtons orientation="horizontal" name={'amount'}
                                                            options={[{value: 0, label: '↓ 300 тыс.'},
                                                                {value: 1, label: '300-600 т.'},
                                                                {value: 2, label: '600-1 млн.',},
                                                                {value: 3, label: '1-1.5 млн.',},
                                                                {value: 4, label: '1.5 млн. ↑'}]}/>
                                        </div>
                                    </div>

                                    <div className={s.customerTask}>
                                        <h4>Задача</h4>
                                        <Field name={'task'} as={'textArea'}>
                                            {(props: any) => {
                                                const {field, form, meta} = props
                                                return (
                                                    <UCTextArea
                                                        placeholder={'Задача'}
                                                        type={'textArea'}
                                                        id={'task'}
                                                        name={'task'}
                                                        {...field}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                    <h4>Ваши контакты</h4>
                                    <div className={s.customerContacts}>


                                        <Field name={'name'}>
                                            {(props: any) => {
                                                const {field, form, meta} = props
                                                return (
                                                    <UCInputText
                                                        placeholder={'Ваше имя'}
                                                        type={'text'}
                                                        id={'name'}
                                                        name={'name'}
                                                        {...field}
                                                        {...meta}
                                                    />
                                                )
                                            }}
                                        </Field>

                                        <Field name={'company'}>
                                            {(props: any) => {
                                                const {field, form, meta} = props
                                                return (
                                                    <UCInputText
                                                        placeholder={'Название компании'}
                                                        type={'text'}
                                                        id={'company'}
                                                        name={'company'}
                                                        {...field}
                                                        {...meta}
                                                    />
                                                )
                                            }}
                                        </Field>


                                        <Field name={'email'}>
                                            {(props: any) => {
                                                const {field, form, meta} = props
                                                return (
                                                    <UCInputText
                                                        placeholder={'E-mail'}
                                                        type={'text'}
                                                        id={'email'}
                                                        name={'email'}
                                                        {...field}
                                                        {...meta}
                                                    />
                                                )
                                            }}
                                        </Field>

                                        <Field name={'phone'}>
                                            {(props: any) => {
                                                const {field, form, meta} = props
                                                return (
                                                    <MaskedUcInput
                                                        mask={phoneNumberMask}
                                                        placeholder={'+7...'}
                                                        labelText={'Ваш номер телефона'}
                                                        type={'text'}
                                                        id={'phone'}
                                                        name={'phone'}
                                                        {...field}
                                                        {...meta}
                                                    />
                                                )
                                            }}
                                        </Field>


                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className={s.buttonsBlock}>

                            <UCButton className={s.button} type="submit">Отправить</UCButton>
                            <p>Нажмая кнопку "Отправить" вы даете согласие</p>
                            <p>на обработку <a href={'https://ucdev.ru/privacy-policy.pdf'}>персональных&nbsp;данных</a>
                            </p>
                            {/*<UCButton className={s.button} type="button" onClick={onClickHandler}>Удалить</UCButton>*/}
                        </div>

                    </Form>
                </div>
            </div>
        </Formik>

    );
};

export default UcForm;