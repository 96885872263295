import React, {useState} from 'react';
import s from './OurServices.module.css'
import site from '../../assets/Site.svg'
import bitrix from '../../assets/Bitrics.svg'
import mobile from '../../assets/Mobile.svg'
import design from '../../assets/Design.svg'
import support from '../../assets/Support.svg'
import system from '../../assets/Sistem.svg'
import wrapper from '../../styles/wrapper-container.module.css'
import {ourServices} from '../../state/ourServices'
import OurServiceCard from '../../common/our-services-cards/OurServiceCard'
import { motion } from 'framer-motion'
import {fromLeft} from '../animations/animations';

const OurServices = () => {

    const onItemClick = (value: string) => {
        //alert(`${value} clicked`)
    }
    const [services, setServices] = useState(ourServices)

    const onCollapseHandler = (value: string) => {
        const isClicked = value
        const newArr = services.map((el: any) => {
            if (el.name === isClicked) {
                el.collapsed = !el.collapsed
            } else {
                el.collapsed = true
            }
            return el
        })
        setServices(newArr)
    };


    return (
        <div id={'services'} className={s.container}>
            <div className={wrapper.wrapper}>
                <motion.div
                  variants={fromLeft}
                  initial={'hidden'}
                  whileInView={'visible'}
                  viewport={{amount: 0.1}}
                  className={s.innerWrapper}>
                    <h2>Наши услуги</h2>
                    <div
                      className={s.contentContainer}>
                        <OurServiceCard header={'Разработка сайтов'} styleType={'big'} cardLogo={site} list={[
                            'Сайт визитка',
                            'Корпоративный сайт',
                            'Интернет-магазин на Битрикс',
                            'Эксклюзивный продукт',
                            'Готовые решения от нашей компани']} />

                        <OurServiceCard header={'Разработка мобильных приложений'} styleType={'medium'} cardLogo={mobile} list={[
                            'Разработка с нуля приложений для Android и iOS',
                            'Проектирование приложений',
                            'Размещения в магазинах приложений']} />

                        <OurServiceCard header={'Разработка дизайна'} styleType={'small'} cardLogo={design} list={[
                            'Функциональный дизайн на основе исследований и аналитики целевой аудитории']} />

                        <OurServiceCard header={'Модернизация систем'} styleType={'small'} cardLogo={system} list={[
                            'Рефакторинг кода',
                            'Повышение производительности',
                            'Настройка единого места взаимодействия с клиентами',
                            'Расширение функционала',
                            'Доработка дизайна сайта',
                          ]} />

                        <OurServiceCard header={'Техническая поддержка'} styleType={'big'} cardLogo={support} list={[
                            `В работах по поддержке сайта принимают участие специалисты того профиля, 
                            который потребуется исходя из поставленной задачи.`,
                            'Наполнение существующих или новых страниц',
                            `Восстановление сайта после модернизации или профилактических работ,
                             проводимых на хостинг-площадке`,
                            `Консультации «Почему не работает мой сайт» 
                            и по вопросам работы с программным продуктом CMS`,
                            'Online консультации в режиме реального времени по вопросам, связанным с работой с CMS']} />

                        <OurServiceCard header={'Внедрение Битрикс.24 CRM'} styleType={'medium'} cardLogo={bitrix} list={[
                            'Выстраивание воронки продаж',
                            'Автоматизация бизнес-процессов',
                            'Настройка единого места взаимодействия с клиентами',
                            'Интеграция с сайтом и продуктами 1С',
                            'Обучение сотрудников',
                            'Поддержка работы портала'
                        ]} />
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default OurServices;