import React, {useState} from 'react';
import {Helmet} from 'react-helmet-async';
import UCModal from '../common/uc-modal/UCModal';
import LittleForm from '../features/uc-formic-form/little-form/LittleForm';
import Header from '../layout/header/Header';
import TitleScreen from '../layout/title-screen/TitleScreen';
import AboutUs from '../layout/about-us/AboutUs';
import OurServices from '../layout/our-services/OurServices';
import Promo from '../layout/promo/Promo';
import UcForm from '../features/uc-formic-form/UcForm';
import {YMaps} from 'react-yandex-maps';
import Contacts from '../layout/contacts/Contacts';
import Footer from '../layout/footer/Footer';

const Content = () => {

    //Modal Form State
    const [modalActive, setModalActive] = useState(false)
    return (
        <div>
            <Helmet>
                <meta name="title" title="Unicode Юникод" content="Юникод - ваша команда разработки"/>
                <meta name="description" title="Разработка, развитие и поддержка веб-сервисов и мобильных приложений"
                      content="Перфекционизм в простых решениях"/>
            </Helmet>
            <UCModal active={modalActive} setActive={setModalActive}>
                <LittleForm/>
                <div>
                    <p>Нажмая кнопку "Отправить" вы даете согласие</p>
                    <p>на обработку <a href={'https://ucdev.ru/privacy-policy.pdf'}>персональных данных</a></p>
                </div>
            </UCModal>
            <Header/>
            <TitleScreen setActive={setModalActive}/>
            <AboutUs/>
            <OurServices/>
            <Promo callBack={setModalActive}/>
            <UcForm/>
            <YMaps><Contacts/></YMaps>
            <Footer/>
        </div>
    );
};

export default Content;