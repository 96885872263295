export const ucSwitchStyle = {
  '& .MuiSwitch-track': {
    width: '76%'
  },
  '& .MuiSwitch-thumb': {
    width: 10,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(9px)',
  },

  '& .MuiSwitch-switchBase': {
    padding: 1.6,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8941E8',
      },
    },

    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: '0.3s',
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#8941E8',
      boxSizing: 'border-box',
    },
  }
}