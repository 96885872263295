import IMask from 'imask';

export let masked = IMask.createMask({
    mask: '+7 (000) 000-00-00',
});


export const phoneNumberMask = [
    "+",
    "7",
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
];
//   /+7\(\d\d\d\)\d\d\d\-\d\d\-\d\d/
//     /\d/,
//     ")",
//     " ",
//     /\d/,
//     /\d/,
//     /\d/,
//     "-",
//     /\d/,
//     /\d/,
//     /\d/,
//     /\d/