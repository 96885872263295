import React from 'react';
import './styles/global.css';
import './styles/variables.css'
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {hydrate, render} from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';


const rootElement = document.getElementById("root") as HTMLElement;
if (rootElement.hasChildNodes()) {
    hydrate(<HelmetProvider><App /></HelmetProvider>, rootElement);
} else {
    render(<HelmetProvider><App /></HelmetProvider>, rootElement);
}
// const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// root.render(
//     <React.StrictMode>
//         <App/>
//     </React.StrictMode>
// );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
