import React from 'react';
import s from './Promo.module.css'
import UCButton from '../../common/button/UCButton';
import {handleClickScroll} from '../../common/scroll-func/scroll';

type PromoPropsType = {
    callBack: (value: boolean) => void
}

const Promo = ({callBack}: PromoPropsType) => {


    return (
        <div className={s.container}>
            <div className={s.promoWrapper}>
                <div className={s.info}>
                    <div>
                        <h2>Запускаем разработку вашего сайта или приложения?</h2>
                    </div>
                    <div>
                        <p>Оставьте&nbsp;заявку и наш менеджер свяжется с вами в течение 15&nbsp;минут</p>
                    </div>
                </div>
                <div className={s.buttons}>
                    <UCButton onClick={() =>callBack(true)} className={s.buttDark}>Оставить заявку</UCButton>
                    <div>или</div>
                    <UCButton onClick={()=>handleClickScroll('form')} className={s.buttViolet}>Заполнить бриф</UCButton>
                </div>

            </div>
        </div>
    );
};

export default Promo;