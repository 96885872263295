import React from 'react';
import s from './Footer.module.css'
import {handleClickScroll} from '../../common/scroll-func/scroll';
import logo from '../../assets/Logo Unicode gray.svg'
import phone from '../../assets/Phone.svg'
import mail from '../../assets/Mail.svg'


const Footer = () => {


    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <div className={s.footerTop}>
                    <img title="go@ucdev.ru" alt={''} className={s.footerLogo} src={logo}/>

                    <ul>
                        <li><a onClick={() => handleClickScroll('about')}>О&nbsp;компании</a></li>
                        <li><a onClick={() => handleClickScroll('services')}>Услуги</a></li>
                        <li><a onClick={() => handleClickScroll('form')}>Заполнить&nbsp;бриф</a></li>
                    </ul>
                </div>

                <div className={s.footerBottom}>
                    <ul>
                        <li className={s.liHead}><b>Юридическая информация</b></li>
                        <li>ООО&nbsp;“Юникод”</li>
                        <li>ИНН&nbsp;9719031131</li>
                        <li>КПП&nbsp;771901001</li>
                        <li>ОГРН&nbsp;1227700639506</li>
                    </ul>
                    <div className={s.divider}></div>
                    <ul>
                        <li className={s.liHead}><b>Адрес</b></li>
                        <li className={s.yurAd}>
                            Юридический адрес: 115404, г. Москва,<br/>
                            ул.&nbsp;Липецкая, д.&nbsp;10, к.&nbsp;2, кв.&nbsp;125
                        </li>
                    </ul>
                    <div className={s.divider}></div>
                    <ul className={s.bottomContacts}>
                        <li className={s.liHead}><b>Контакты</b></li>
                        <li>
                            <img alt={''} src={phone}/>
                            Телефон: <a href={'tel:+74951451085'}>
                            +7&nbsp;(495)&nbsp;145&nbsp;10&nbsp;85
                            </a>
                        </li>
                        <li>
                            <img alt={''} src={mail}/>
                            Почта:
                            <a href={'mailto:go@ucdev.ru'}>
                                go@ucdev.ru
                            </a>
                        </li>
                    </ul>


                </div>


                <div className={s.footerInfoMobile}>
                    <div className={s.footerElement}>
                        <h4>ООО&nbsp;“Юникод”</h4>
                    </div>
                    <div className={s.footerElement}>
                        <ul>
                            <li className={s.refs} onClick={() => handleClickScroll('about')}>О&nbsp;компании</li>
                            <li className={s.refs} onClick={() => handleClickScroll('services')}>Услуги</li>
                            <li className={s.refs} onClick={() => handleClickScroll('form')}>Заполнить&nbsp;бриф</li>
                        </ul>
                    </div>
                    <div className={s.footerElement}>
                        <ul>
                            <li>
                                <a href={'tel:+74951451085'}>
                                    +7&nbsp;(495)&nbsp;145&nbsp;10&nbsp;85
                                </a>
                            </li>
                            <li>
                                <a href={"mailto:go@ucdev.ru"}>go@ucdev.ru</a>
                            </li>
                        </ul>
                    </div>
                    <div className={s.footerElement}>
                        <ul>
                            <li className={s.yurAd}>
                                Юридический адрес: 115404, г. Москва,<br/>
                                ул.&nbsp;Липецкая, д.&nbsp;10, к.&nbsp;2, кв.&nbsp;125
                            </li>
                        </ul>
                    </div>
                    <div className={s.footerElement}>
                        <ul>
                            <li>ИНН&nbsp;9719031131</li>
                            <li>КПП&nbsp;771901001</li>
                            <li>ОГРН&nbsp;1227700639506</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Footer;