import React, {useEffect, useState} from 'react';
import {Field} from 'formik';
import Slider from '@mui/material/Slider';
import {ucSliderStyle, ucSliderStyleDisabled} from '../slider-in-form/UCSliderStyle';
import Box from '@mui/material/Box';
import {Switch} from '@mui/material';
import {ucSwitchStyle} from './UCSwitchStyle';

const UcRadioButtons = (props: any) => {
    const { options, orientation } = props

    const [opt, setOpt] = useState(options)
    const [checked, setChecked] = useState(false)

    function toggle() {
        setChecked(!checked)
        setOpt(checked ? options : [{value: 0, label: 'Не знаю',}])
    }

    return (

        <Field name={'amount'}>
            {
                ({field}: any) => {
                    return <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '20px'
                    }}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}>
                            <p>Не знаю</p>
                            <Switch
                              sx={ucSwitchStyle}
                              checked={checked}
                              onChange={toggle}
                              color={'primary'}/>
                        </div>

                        <Slider
                            sx={!checked ? ucSliderStyle : ucSliderStyleDisabled}
                            orientation={orientation}
                            min={0}
                            max={!checked ? opt.length - 1 : 0}
                            step={1}
                            value={checked && {value: 0, label: 'Не знаю',}}
                            marks={!checked ? opt : [{value: 0, label: 'Не знаю',}]}
                            {...field}
                        >
                        </Slider>
                    </Box>
                }
            }
        </Field>

    );
};

export default UcRadioButtons;