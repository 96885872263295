import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars(props: any) {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        props.setFlag(false)
        return;

    };

    return (
        <Stack spacing={2} >
            <Snackbar open={props.flag} autoHideDuration={6000} onClose={handleClose}>
                {!props.error
                    ? <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Мы свяжемся с вами в ближайшее время!
                </Alert>
                : <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {props.error}
                    </Alert>
                }
            </Snackbar>
        </Stack>
    );
}
