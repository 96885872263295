import React from 'react';
import {Field} from 'formik';
import s from '../../common/customer-choice-button/CustomerChoiceCheckbox.module.css';

const UcCheckBoxGroup = (props: any) => {
    const {label, name, options, ...rest} = props

    return (

            <Field name={name} {...rest}>
                {({field}: any) => {
                        return options.map((o: any) => {
                            return (
                                <div key={o.value} className={s.cccContainer}>
                                    <input
                                        id={o.value}
                                        className={s.input}
                                        type={'checkbox'}
                                        {...field}
                                        checked={field.value.includes(o.value)}
                                        value={o.value}
                                    />
                                    <label htmlFor={o.value} className={s.checkBoxLabel}>
                                        {o.value}
                                    </label>
                                </div>)
                        })}}
            </Field>

    );
};
export default UcCheckBoxGroup;