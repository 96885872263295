import React, {
    ChangeEvent,
    DetailedHTMLProps,
    KeyboardEvent,
    TextareaHTMLAttributes,
    useState
} from 'react'
import s from './UCTextArea.module.css'
import Focus from '../focus/Focus';
import PlaceHolderLabel from '../place-holder-label/PlaceHolderLabel';

type DefaultTextAreaPropsType = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>


type UCTextAreaPropsType = DefaultTextAreaPropsType & {
    onChangeText?: (value: string) => void
    onEnter?: () => void
    error?: string
    spanClassName?: string
}

const UCTextArea: React.FC<UCTextAreaPropsType> = (
    {
        onChange, onChangeText,
        onKeyPress, onEnter,
        error,
        className, spanClassName,
        placeholder,
        ...restProps
    }
) => {

    const [focused, setFocused] = useState(false)

    const onChangeCallback = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange // if prop onChange
        && onChange(e)

        onChangeText && onChangeText(e.currentTarget.value)
    }
    const onKeyPressCallback = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        onKeyPress && onKeyPress(e);

        onEnter // if prop onEnter
        && e.key === 'Enter'
        && onEnter()
    }

    const finalSpanClassName = `${s.error} ${spanClassName ? spanClassName : ''}`
    const finalInputClassName = `${error ? s.errorInput : s.UCTextArea} ${className}`

    return (
        <div className={s.UCTextAreaContainer}>
          <PlaceHolderLabel type={''} focused={focused || restProps.value !== ''} text={placeholder}/>
            <textarea
                onFocus={()=>setFocused(true)}
                onBlurCapture={()=>setFocused(false)}
                onChange={onChangeCallback}
                onKeyPress={onKeyPressCallback}
                className={finalInputClassName}
                {...restProps} //(value inside)
            />

            <Focus focused={focused}/>
            {error && <span style={{fontSize: '25px'}} className={finalSpanClassName}>{error}</span>}
        </div>
    )
}

export default UCTextArea
