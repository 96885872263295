import s from './our-services-card.module.css'

interface IProps {
  header: string;
  styleType: string;
  cardLogo: string;
  list?: any
}
const OurServiceCard = (props: IProps) => {
  return (
    <div className={`${s.container} ${s[props.styleType]}`}>
      <div className={s.cardLogo}><img alt={''} src={props.cardLogo}></img></div>
      <div className={s.content}>
        <h3 className={s.header}>{props.header}</h3>
        <ul>
        {props.list.map((el: string, idx: number) => {
          return (
            <li key={idx}>{el}</li>
          )
        })}
        </ul>
      </div>

    </div>
  );
};

export default OurServiceCard;