import React, {useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Error404 from '../common/404/Error404';
import Content from './Content';
import AboutUs from '../layout/about-us/AboutUs';
import TitleScreen from '../layout/title-screen/TitleScreen';
import OurServices from '../layout/our-services/OurServices';
import UcForm from '../features/uc-formic-form/UcForm';



function App() {
    //Modal Form State
    const [modalActive, setModalActive] = useState(false)
    return (
        <div>
            <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<Content/>}/>
                <Route path={'/title'} element={<TitleScreen  setActive={setModalActive}/>}/>
                <Route path={'/about'} element={<AboutUs/>}/>
                <Route path={'/service'} element={<OurServices/>}/>
                <Route path={'/form'} element={<UcForm/>}/>
                <Route path={'https://www.ucdev.ru/*'} element={<Error404/>}/>
            </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
