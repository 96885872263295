import React, {useEffect, useState} from 'react';
import s from './Header.module.css'
import {ReactComponent as Logo} from '../../assets/Logo_Unicode.svg';
import {handleClickScroll} from '../../common/scroll-func/scroll';
import {motion, useScroll} from 'framer-motion'
import logo from '../../assets/Logo_UC.svg'
import UCDropDownMenu from '../../features/drop-down-menu/UCDropDownMenu';


const Header = () => {

    const {scrollY} = useScroll()
    const [a, setA] = useState(0)

    useEffect(() => {
        return scrollY.onChange((latest) => {
            setA(latest)

        })
    }, [])


    return (
      <div className={s.main}>
            <div className={s.header}>

                <div className={s.left}>

                    <Logo className={a < 500 ? s.headerLogo : s.headerLogoDessapear}
                          onClick={() => handleClickScroll('about')}/>

                    <motion.div
                        onClick={() => handleClickScroll('form')}
                        className={a > 500 ? s.becomeAClient : s.becomeAClientInvisible}>

                    </motion.div>

                    <motion.div
                        onClick={() => handleClickScroll('title')}
                        className={a > 500 ? s.uniCodeLogo : s.uniCodeLogoDissaper}>
                        <img alt={''} src={logo}/>
                    </motion.div>



                <UCDropDownMenu/>
                </div>
                <div className={s.contactsInfo}>
                    <span><a className={(a > 460 && a < 1548) ? s.a_active : ''} onClick={() => handleClickScroll('about')}>О&nbsp;компании&nbsp;</a></span>
                    <span><a className={(a > 1547 && a < 2533) ? s.a_active : ''} onClick={() => handleClickScroll('services')}>Услуги&nbsp;</a></span>
                    <span><a className={(a > 2533) ? s.a_active : ''} onClick={() => handleClickScroll('form')}>Заполнить&nbsp;бриф&nbsp;</a></span>
                    <span className={s.mailAndPhone}><a
                        href={'mailto:go@ucdev.ru'}>go@ucdev.ru&nbsp;</a></span>
                    <span className={s.mailAndPhone}><a
                        href={'tel:+74951451085'}>+7&nbsp;(495)&nbsp;145&#8209;10&#8209;85&nbsp;</a></span>
                </div>
            </div>
      </div>
    );
};

export default Header;